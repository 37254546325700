import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Signup.module.css";
import Cookies from "js-cookie";
import logo from "../../assets/pivotea-logo.png";

export default function Signup() {
  const navigate = useNavigate();
  const { setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false); // New loading state

  const url = process.env.REACT_APP_API_URL || "http://localhost:3501";

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    } else {
      const authCookie = Cookies.get("authUser");
      if (authCookie) {
        const user = JSON.parse(authCookie);
        setAuthUser(user);
        setIsLoggedIn(true);
        navigate("/");
      }
    }
  }, [isLoggedIn, navigate, setAuthUser, setIsLoggedIn]);

  const onSignup = async (e) => {
    e.preventDefault();
    setLoading(true); // Show the loader when clicked
    try {
      const response = await axios.post(`${url}/auth/signup`, {
        email,
        password: pass,
        name,
        last_name: lastName,
      });
      setIsLoggedIn(true);
      Cookies.set("authUser", JSON.stringify(response.data.data.user), { expires: 7 });
      setAuthUser({
        name: response.data.data.user.name,
        lastName: response.data.data.user.last_name,
        id: response.data.data.user.id_user,
        email: response.data.data.user.email,
        token: response.data.token,
      });
      navigate("/");
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false); // Hide the loader once done
    }
  };

  useEffect(() => {
    if (email && pass && name && lastName && terms) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email, pass, name, lastName, terms]);

  return (
    <div className={styles.signupLayout}>
      <img src={logo} alt="Pivotea" />
      <h1>Regístrate</h1>
      <div className={styles.signupForm}>
        <form onSubmit={onSignup}>
          <input
            type="text"
            placeholder="Nombre(s)"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Apellidos"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Contraseña"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            required
          />
          <div className="privacy">
            <input
              type="checkbox"
              id="privacy"
              checked={privacy}
              onChange={(e) => setPrivacy(e.target.checked)}
              required
            />
            <label htmlFor="privacy">
              Acepto y estoy de acuerdo con el{" "}
              <a
                className={styles.link}
                href="https://tecmilenio.mx/es/aviso-de-privacidad/sc"
                target="_blank"
                rel="noopener noreferrer"
              >
                aviso de privacidad
              </a>
            </label>

          </div>
          <div className="terms">
            <input
              type="checkbox"
              id="terms"
              checked={terms}
              onChange={(e) => setTerms(e.target.checked)}
              required
            />
            <label htmlFor="terms">
              Acepto y estoy de acuerdo con los{" "}
              <a
                className={styles.link}
                href="/terminos"
                target="_blank"
                rel="noopener noreferrer"
              >
                terminos y condiciones
              </a>
            </label>
          </div>
          {error && (
            <div className={styles.errorMsg}>
              <p>Todos los campos son obligatorios y el correo debe ser válido.</p>
            </div>
          )}
          <button
            className={`${styles.signupForm} ${styles.btn} ${styles.btnFilled}`}
            disabled={disabled || loading} // Disable button when loading
          >
            {loading ? <span className={styles.loader}></span> : "Registrarse"} {/* Show loader */}
          </button>
        </form>
      </div>
      <p>
        ¿Ya tienes un perfil?{" "}
        <a className={styles.link} onClick={() => navigate("/login")}>
          Inicia Sesión
        </a>
      </p>
    </div>
  );
}
