import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Sidebar({ title, steps: initialSteps, goToStep, currentStep, nextModule, prevModule }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [sidebarSteps, setSidebarSteps] = useState(initialSteps); 

  useEffect(() => {
    setSidebarSteps(initialSteps);
  }, [initialSteps]);

  const toggleSidebar = () => {
    setIsCollapsed(prevState => !prevState);
  };

  const handleResize = () => {
    if (window.innerWidth < 765) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  };

  useEffect(() => {
    setIsMounted(true); // Indicar que el componente está montado
    handleResize(); // Comprobación inicial del tamaño de la ventana
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderedSteps = sidebarSteps.map((step, index) => (
    <div
      key={index}
      className={`step step-${step.status} ${currentStep.current === step.id ? 'current' : ''}`}
      onClick={() => {
        if (step.status === 'complete') {
          goToStep({ ...step, number: index + 1 });
        }
      }}
    >
      <div className={`index index-${step.status}`}>{index + 1}</div>
      <p>{step.title}</p>
    </div>
  ));

  return (
    <div className={`sidebar ${isCollapsed && isMounted ? 'collapsed' : ''}`}>
      <div className="sidebar-title">
        <p>{title.includes(":") ? title.split(": ")[1] : title}</p>
        <IconButton onClick={toggleSidebar} className="collapse-button">
          {isCollapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </IconButton>
      </div>
      {!isCollapsed && renderedSteps}
    </div>
  );
}

export default Sidebar;
