import React from 'react'
import styles from './Error.module.css'
import { useNavigate } from "react-router-dom";
import logo from "../../assets/pivotea-logo.png";

function Error() {
    const navigate = useNavigate();

    return (
        <div className={styles.errorLayout}>
        <img src={logo} alt="Pivotea" />
        <h1>Página No Encontrada</h1>
        <div className={styles.errorContent}>
          <p>Lo sentimos, la página que buscas no existe o ha sido movida.</p>
          <button className="errorForm btn btn-filled" onClick={() => navigate('/')}>
            Volver al Inicio
          </button>
        </div>
      </div>
  )
}

export default Error;