import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import "./Card.css";

export default function Card(props) {
  
  const userFree = localStorage.getItem('userFree')
  
  const navigate = useNavigate();
  const button =
    props.status === "in progress" ? (
      <Button
        size="sm"
        text="Continuar"
        type="outline"
        corners="rounded"
        onClick={() => { userFree  === 'true' ?  navigate(props.link) : window.open('https://pivotea.nowisnao.com/')}}
      />
    ) : props.status === "complete" ? (
      <Button size="sm" text="Terminado" type="disabled" corners="rounded" />
    ) : (
      <Button
        size="sm"
        text={props.status}
        type="outline"
        corners="rounded"
        onClick={() => { userFree  === 'true'  ?  navigate(props.link) : window.open('https://pivotea.nowisnao.com/')}}
      />
    )
  return (
    <div className="card">
      <div
        className="card-cover"
        style={{ backgroundImage: `url(${props.cover})` }}
      ></div>
      <div className="card-info">
        <h3>{props.title}</h3>
        <p>{props.description}</p>
        <span>{`${props.duration} · ${props.participants}`}</span>
        {props.linkType === "external" ? (
          <a className="card-btn" href={props.link} target="_blank">
            {props.status}
          </a>
        ) : (<>{button}</>)}
      </div>
    </div>
  );
}
