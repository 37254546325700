import React from 'react';
import { useTimer } from 'react-timer-hook';
import PropTypes from 'prop-types';


function Timer({ expiryTimestamp, callback }) {
  const {
    seconds,
    minutes
  } = useTimer({ expiryTimestamp, onExpire: () => callback() });


  return (
        <p className='password-msg'>Puedes volver a intentarlo en {minutes}:{seconds > 9 ? seconds : `0${seconds}`}</p>
  );
}

Timer.propTypes = {
  expiryTimestamp: PropTypes.instanceOf(Date).isRequired,
  callback: PropTypes.func.isRequired,
};

export default Timer;