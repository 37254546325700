import axios from 'axios';

import './ProyectoDeAprendizaje.css'
import React, { useState, useEffect } from 'react';
import Cookies from "js-cookie";

import { ThreeDots } from "react-loader-spinner";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

import { EmptySidebar } from '../../components';
import ProyectoAprendizaje from '../ProyectoAprendizaje/ProyectoAprendizaje';
import Tendencias from '../Tendencias/Tendencias';
import Escenarios from '../Escenarios/Escenarios';

import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

const ProyectoDeAprendizaje = () => {

    const [currentTab, setCurrentTab] = useState(0);
    const [dataFirst, setDataFirst] = useState(null)
    const [dataSecond, setDataSecond] = useState(null)
    const [dataThrid, setDataThrid] = useState(null)
    const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
    const navigate = useNavigate();

    const url = process.env.REACT_APP_API_URL
        ? process.env.REACT_APP_API_URL
        : "http://localhost:3501";

    const getData = () => {
        const email = authUser?.email.replace(/"/g, '');
        axios.get(`${url}/answers/get-learning-project-first/${email.toString()}`)
            .then((response) => {
                setDataFirst(response.data.data);
            })
            .catch((error) => console.log(error));

        axios.get(`${url}/answers/get-learning-project-second/${email.toString()}`)
            .then((response) => {
                setDataSecond(response.data.data);
            })
            .catch((error) => console.log(error));

        axios.get(`${url}/answers/get-learning-project-third/${email.toString()}`)
            .then((response) => {
                setDataThrid(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    const sendLearningProjectEmail = async () => {
        try {
            const response = await fetch('http://localhost:3501/auth/send-learning-project-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                const errorText = await response.text(); 
                console.error('Error sending email:', errorText);
                alert('Error: ' + errorText);
                return;
            }

            const result = await response.json();
            alert('Success: ' + result.message);
        } catch (error) {
            console.error('Network error:', error);
            alert('Network error: Unable to send email. Please try again later.');
        }
    };



    useEffect(() => {
        if (isLoggedIn) {

            if (authUser.current_module !== 'ffc340f4-eea3-4ab5-8430-d65fb062a6e8') {
                window.location.href = '/miespacio';
                return
            }

            getData();
        }
    }, [authUser, isLoggedIn]);

    useEffect(() => {
        const storedUser = Cookies.get("authUser");
        if (!isLoggedIn && storedUser) {
            const user = JSON.parse(storedUser);
            if (user && user.token) {
                setAuthUser(user);
                setIsLoggedIn(true);
            } else {
                setAuthUser(null);
                setIsLoggedIn(false);
                navigate("/login");
            }
        } else if (!isLoggedIn) {
            navigate("/login");
        }

        if (isLoggedIn) {
            if (authUser?.current_module !== 'ffc340f4-eea3-4ab5-8430-d65fb062a6e8') {
                window.location.href = '/miespacio';
                return;
            }
            getData();
        }
    }, []);

    const options = [
        {
            id: 0,
            name: "Mi propósito de vida",
            icon: <ArrowForwardIosRoundedIcon />,
            onClick: () => setCurrentTab(0),
        },
        {
            id: 1,
            name: "Tendencias aspiraciones profesionales",
            icon: <ArrowBackIosRoundedIcon />,
            onClick: () => setCurrentTab(1),
        },
        {
            id: 2,
            name: "Escenarios",
            icon: <ArrowBackIosRoundedIcon />,
            onClick: () => setCurrentTab(2),
        },
    ];

    const content = (currentTab) => {
        switch (currentTab) {
            case 0:
                return (
                    dataFirst === null
                        ?
                        <ThreeDots
                            visible={true}
                            height="80"
                            width="80"
                            color="var(--primary)"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        :
                        <ProyectoAprendizaje aprendizajeData={dataFirst} />
                );
            case 1:
                return (
                    dataSecond === null
                        ?
                        <ThreeDots
                            visible={true}
                            height="80"
                            width="80"
                            color="var(--primary)"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        :
                        <Tendencias tendenciasData={dataSecond} />
                );
            case 2:
                return (
                    dataThrid === null
                        ?
                        <ThreeDots
                            visible={true}
                            height="80"
                            width="80"
                            color="var(--primary)"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        :
                        <Escenarios escenariosData={dataThrid} />
                );
            default:
                return (
                    <div>
                        <h1>Proyecto de Aprendizaje</h1>
                    </div>
                );
        }
    }

    return (
        <>
            <div className="aprendizaje-layout">
                <div className="col-25">
                    <EmptySidebar
                        currentOption={currentTab}
                        options={options}
                        title="Proyecto de Aprendizaje"
                    />
                </div>
                <div className="col-75" id="section-to-print">
                    {content(currentTab)}
                </div>
            </div>
        </>

    );
}

export default ProyectoDeAprendizaje;