import css from './FieldTextInput.module.css';

const FieldTextInput = (props) => {
    
        const { 
            label,
            name,
            type,
            value,
            onChange,
            className
        } = props;

        if (type === 'textarea') {
            return (
                <div className={`${css.container} ${className}`}>
                    <label className={css.label} htmlFor={name}>{label}</label>
                    <textarea 
                        className={css.textarea} 
                        name={name} 
                        id={name} 
                        value={value} 
                        onChange={onChange} 
                    />
                </div>
            )
        }
    
        return (
            <div className={`${css.container} ${className}`}>
                <label className={css.label} htmlFor={name}>{label}</label>
                <input 
                    className={css.input} 
                    type={type} 
                    name={name} 
                    id={name} 
                    value={value} 
                    onChange={onChange} 
                />
            </div>
        )
}

export default FieldTextInput;