// America countries
export const countries = [
    { "value": "Argentina", "label": "Argentina", "dialCode": "+54" },
    { "value": "Bolivia", "label": "Bolivia", "dialCode": "+591" },
    { "value": "Brazil", "label": "Brazil", "dialCode": "+55" },
    { "value": "Chile", "label": "Chile", "dialCode": "+56" },
    { "value": "Colombia", "label": "Colombia", "dialCode": "+57" },
    { "value": "Ecuador", "label": "Ecuador", "dialCode": "+593" },
    { "value": "Guyana", "label": "Guyana", "dialCode": "+592" },
    { "value": "Paraguay", "label": "Paraguay", "dialCode": "+595" },
    { "value": "Peru", "label": "Peru", "dialCode": "+51" },
    { "value": "Suriname", "label": "Suriname", "dialCode": "+597" },
    { "value": "Uruguay", "label": "Uruguay", "dialCode": "+598" },
    { "value": "Venezuela", "label": "Venezuela", "dialCode": "+58" },
    { "value": "United States", "label": "United States", "dialCode": "+1" },
    { "value": "Canada", "label": "Canada", "dialCode": "+1" },
    { "value": "Mexico", "label": "Mexico", "dialCode": "+52" },
    { "value": "Guatemala", "label": "Guatemala", "dialCode": "+502" },
    { "value": "Belize", "label": "Belize", "dialCode": "+501" },
    { "value": "Honduras", "label": "Honduras", "dialCode": "+504" },
    { "value": "El Salvador", "label": "El Salvador", "dialCode": "+503" },
    { "value": "Nicaragua", "label": "Nicaragua", "dialCode": "+505" },
    { "value": "Costa Rica", "label": "Costa Rica", "dialCode": "+506" },
    { "value": "Panama", "label": "Panama", "dialCode": "+507" },
    { "value": "Cuba", "label": "Cuba", "dialCode": "+53" },
    { "value": "Dominican Republic", "label": "Dominican Republic", "dialCode": "+1-809" },
    { "value": "Jamaica", "label": "Jamaica", "dialCode": "+1-876" },
    { "value": "Trinidad and Tobago", "label": "Trinidad and Tobago", "dialCode": "+1-868" },
    { "value": "Barbados", "label": "Barbados", "dialCode": "+1-246" },
    { "value": "Saint Lucia", "label": "Saint Lucia", "dialCode": "+1-758" },
    { "value": "Saint Vincent and the Grenadines", "label": "Saint Vincent and the Grenadines", "dialCode": "+1-784" },
    { "value": "Antigua and Barbuda", "label": "Antigua and Barbuda", "dialCode": "+1-268" },
    { "value": "Grenada", "label": "Grenada", "dialCode": "+1-473" },
    { "value": "Saint Kitts and Nevis", "label": "Saint Kitts and Nevis", "dialCode": "+1-869" },
    { "value": "Bermuda", "label": "Bermuda", "dialCode": "+1-441" }
  ]

export const lada = [
    { "value": "+54", "label": "+54", "country": "Argentina" },
    { "value": "+591", "label": "+591", "country": "Bolivia" },
    { "value": "+55", "label": "+55", "country": "Brazil" },
    { "value": "+56", "label": "+56", "country": "Chile" },
    { "value": "+57", "label": "+57", "country": "Colombia" },
    { "value": "+593", "label": "+593", "country": "Ecuador" },
    { "value": "+592", "label": "+592", "country": "Guyana" },
    { "value": "+595", "label": "+595", "country": "Paraguay" },
    { "value": "+51", "label": "+51", "country": "Peru" },
    { "value": "+597", "label": "+597", "country": "Suriname" },
    { "value": "+598", "label": "+598", "country": "Uruguay" },
    { "value": "+58", "label": "+58", "country": "Venezuela" },
    { "value": "+1", "label": "+1", "country": "United States" },
    { "value": "+1", "label": "+1", "country": "Canada" },
    { "value": "+52", "label": "+52", "country": "Mexico" },
    { "value": "+502", "label": "+502", "country": "Guatemala" },
    { "value": "+501", "label": "+501", "country": "Belize" },
    { "value": "+504", "label": "+504", "country": "Honduras" },
    { "value": "+503", "label": "+503", "country": "El Salvador" },
    { "value": "+505", "label": "+505", "country": "Nicaragua" },
    { "value": "+506", "label": "+506", "country": "Costa Rica" },
    { "value": "+507", "label": "+507", "country": "Panama" },
    { "value": "+53", "label": "+53", "country": "Cuba" },
    { "value": "+1-809", "label": "+1-809", "country": "Dominican Republic" },
    { "value": "+1-876", "label": "+1-876", "country": "Jamaica" },
    { "value": "+1-868", "label": "+1-868", "country": "Trinidad and Tobago" },
    { "value": "+1-246", "label": "+1-246", "country": "Barbados" },
    { "value": "+1-758", "label": "+1-758", "country": "Saint Lucia" },
    { "value": "+1-784", "label": "+1-784", "country": "Saint Vincent and the Grenadines" },
    { "value": "+1-268", "label": "+1-268", "country": "Antigua and Barbuda" },
    { "value": "+1-473", "label": "+1-473", "country": "Grenada" },
    { "value": "+1-869", "label": "+1-869", "country": "Saint Kitts and Nevis" },
    { "value": "+1-441", "label": "+1-441", "country": "Bermuda" },
]

export const states = {
  "Argentina": [
    { "value": "Buenos Aires", "label": "Buenos Aires" },
    { "value": "Catamarca", "label": "Catamarca" },
    { "value": "Chaco", "label": "Chaco" },
    { "value": "Chubut", "label": "Chubut" },
    { "value": "CABA", "label": "CABA" },
    { "value": "Córdoba", "label": "Córdoba" },
    { "value": "Corrientes", "label": "Corrientes" },
    { "value": "Entre Ríos", "label": "Entre Ríos" },
    { "value": "Formosa", "label": "Formosa" },
    { "value": "Jujuy", "label": "Jujuy" },
    { "value": "La Pampa", "label": "La Pampa" },
    { "value": "La Rioja", "label": "La Rioja" },
    { "value": "Mendoza", "label": "Mendoza" },
    { "value": "Misiones", "label": "Misiones" },
    { "value": "Neuquén", "label": "Neuquén" },
    { "value": "Río Negro", "label": "Río Negro" },
    { "value": "Salta", "label": "Salta" },
    { "value": "San Juan", "label": "San Juan" },
    { "value": "San Luis", "label": "San Luis" },
    { "value": "Santa Cruz", "label": "Santa Cruz" },
    { "value": "Santa Fe", "label": "Santa Fe" },
    { "value": "Santiago del Estero", "label": "Santiago del Estero" },
    { "value": "Tierra del Fuego", "label": "Tierra del Fuego" },
    { "value": "Tucumán", "label": "Tucumán" }
  ],
  "Bolivia": [
    { "value": "Chuquisaca", "label": "Chuquisaca" },
    { "value": "Cochabamba", "label": "Cochabamba" },
    { "value": "Colchani", "label": "Colchani" },
    { "value": "El Alto", "label": "El Alto" },
    { "value": "La Paz", "label": "La Paz" },
    { "value": "Oruro", "label": "Oruro" },
    { "value": "Pando", "label": "Pando" },
    { "value": "Potosí", "label": "Potosí" },
    { "value": "Santa Cruz", "label": "Santa Cruz" },
    { "value": "Tarija", "label": "Tarija" }
  ],
  "Brazil": [
    { "value": "Acre", "label": "Acre" },
    { "value": "Alagoas", "label": "Alagoas" },
    { "value": "Amapá", "label": "Amapá" },
    { "value": "Amazonas", "label": "Amazonas" },
    { "value": "Bahia", "label": "Bahia" },
    { "value": "Ceará", "label": "Ceará" },
    { "value": "Distrito Federal", "label": "Distrito Federal" },
    { "value": "Espírito Santo", "label": "Espírito Santo" },
    { "value": "Goiás", "label": "Goiás" },
    { "value": "Maranhão", "label": "Maranhão" },
    { "value": "Mato Grosso", "label": "Mato Grosso" },
    { "value": "Mato Grosso do Sul", "label": "Mato Grosso do Sul" },
    { "value": "Minas Gerais", "label": "Minas Gerais" },
    { "value": "Pará", "label": "Pará" },
    { "value": "Paraíba", "label": "Paraíba" },
    { "value": "Paraná", "label": "Paraná" },
    { "value": "Pernambuco", "label": "Pernambuco" },
    { "value": "Piauí", "label": "Piauí" },
    { "value": "Rio de Janeiro", "label": "Rio de Janeiro" },
    { "value": "Rio Grande do Norte", "label": "Rio Grande do Norte" },
    { "value": "Rio Grande do Sul", "label": "Rio Grande do Sul" },
    { "value": "Rondônia", "label": "Rondônia" },
    { "value": "Roraima", "label": "Roraima" },
    { "value": "Santa Catarina", "label": "Santa Catarina" },
    { "value": "São Paulo", "label": "São Paulo" },
    { "value": "Sergipe", "label": "Sergipe" },
    { "value": "Tocantins", "label": "Tocantins" }
  ],
  "Chile": [
    { "value": "Arica y Parinacota", "label": "Arica y Parinacota" },
    { "value": "Atacama", "label": "Atacama" },
    { "value": "Antofagasta", "label": "Antofagasta" },
    { "value": "Biobío", "label": "Biobío" },
    { "value": "Coquimbo", "label": "Coquimbo" },
    { "value": "La Araucanía", "label": "La Araucanía" },
    { "value": "Libertador General Bernardo O'Higgins", "label": "Libertador General Bernardo O'Higgins" },
    { "value": "Los Lagos", "label": "Los Lagos" },
    { "value": "Los Ríos", "label": "Los Ríos" },
    { "value": "Magallanes y de la Antártica Chilena", "label": "Magallanes y de la Antártica Chilena" },
    { "value": "Metropolitana de Santiago", "label": "Metropolitana de Santiago" },
    { "value": "Ñuble", "label": "Ñuble" },
    { "value": "Tarapacá", "label": "Tarapacá" }
  ],
  "Colombia": [
    { "value": "Amazonas", "label": "Amazonas" },
    { "value": "Antioquia", "label": "Antioquia" },
    { "value": "Arauca", "label": "Arauca" },
    { "value": "Atlántico", "label": "Atlántico" },
    { "value": "Bolívar", "label": "Bolívar" },
    { "value": "Boyacá", "label": "Boyacá" },
    { "value": "Caldas", "label": "Caldas" },
    { "value": "Caquetá", "label": "Caquetá" },
    { "value": "Casanare", "label": "Casanare" },
    { "value": "Cauca", "label": "Cauca" },
    { "value": "Cesar", "label": "Cesar" },
    { "value": "Chocó", "label": "Chocó" },
    { "value": "Córdoba", "label": "Córdoba" },
    { "value": "Cundinamarca", "label": "Cundinamarca" },
    { "value": "Guainía", "label": "Guainía" },
    { "value": "Guaviare", "label": "Guaviare" },
    { "value": "Guajira", "label": "Guajira" },
    { "value": "Huila", "label": "Huila" },
    { "value": "La Guajira", "label": "La Guajira" },
    { "value": "Magdalena", "label": "Magdalena" },
    { "value": "Meta", "label": "Meta" },
    { "value": "Nariño", "label": "Nariño" },
    { "value": "Norte de Santander", "label": "Norte de Santander" },
    { "value": "Putumayo", "label": "Putumayo" },
    { "value": "Quindío", "label": "Quindío" },
    { "value": "Risaralda", "label": "Risaralda" },
    { "value": "San Andrés y Providencia", "label": "San Andrés y Providencia" },
    { "value": "Santander", "label": "Santander" },
    { "value": "Sucre", "label": "Sucre" },
    { "value": "Tolima", "label": "Tolima" },
    { "value": "Valle del Cauca", "label": "Valle del Cauca" },
    { "value": "Vaupés", "label": "Vaupés" },
    { "value": "Vichada", "label": "Vichada" }
  ],
  "Ecuador": [
    { "value": "Azuay", "label": "Azuay" },
    { "value": "Bolívar", "label": "Bolívar" },
    { "value": "Cañar", "label": "Cañar" },
    { "value": "Carchi", "label": "Carchi" },
    { "value": "Chimborazo", "label": "Chimborazo" },
    { "value": "Cotopaxi", "label": "Cotopaxi" },
    { "value": "El Oro", "label": "El Oro" },
    { "value": "Esmeraldas", "label": "Esmeraldas" },
    { "value": "Galápagos", "label": "Galápagos" },
    { "value": "Guayas", "label": "Guayas" },
    { "value": "Imbabura", "label": "Imbabura" },
    { "value": "Loja", "label": "Loja" },
    { "value": "Los Ríos", "label": "Los Ríos" },
    { "value": "Manabí", "label": "Manabí" },
    { "value": "Morona Santiago", "label": "Morona Santiago" },
    { "value": "Napo", "label": "Napo" },
    { "value": "Orellana", "label": "Orellana" },
    { "value": "Pastaza", "label": "Pastaza" },
    { "value": "Pichincha", "label": "Pichincha" },
    { "value": "Santa Elena", "label": "Santa Elena" },
    { "value": "Santo Domingo de los Tsáchilas", "label": "Santo Domingo de los Tsáchilas" },
    { "value": "Sucumbíos", "label": "Sucumbíos" },
    { "value": "Tungurahua", "label": "Tungurahua" },
    { "value": "Zamora-Chinchipe", "label": "Zamora-Chinchipe" }
  ],
  "Guyana": [
    { "value": "Barima-Waini", "label": "Barima-Waini" },
    { "value": "Cuyuni-Mazaruni", "label": "Cuyuni-Mazaruni" },
    { "value": "Demerara-Mahaica", "label": "Demerara-Mahaica" },
    { "value": "Essequibo Islands-West Demerara", "label": "Essequibo Islands-West Demerara" },
    { "value": "Mahaica-Berbice", "label": "Mahaica-Berbice" },
    { "value": "Pomeroon-Supenaam", "label": "Pomeroon-Supenaam" },
    { "value": "Upper Demerara-Berbice", "label": "Upper Demerara-Berbice" },
    { "value": "Upper Takutu-Upper Essequibo", "label": "Upper Takutu-Upper Essequibo" }
  ],
  "Paraguay": [
    { "value": "Alto Paraná", "label": "Alto Paraná" },
    { "value": "Amambay", "label": "Amambay" },
    { "value": "Boquerón", "label": "Boquerón" },
    { "value": "Caaguazú", "label": "Caaguazú" },
    { "value": "Caazapá", "label": "Caazapá" },
    { "value": "Central", "label": "Central" },
    { "value": "Concepción", "label": "Concepción" },
    { "value": "Cordillera", "label": "Cordillera" },
    { "value": "Guairá", "label": "Guairá" },
    { "value": "Itapúa", "label": "Itapúa" },
    { "value": "Misiones", "label": "Misiones" },
    { "value": "Ñeembucú", "label": "Ñeembucú" },
    { "value": "Paraguarí", "label": "Paraguarí" },
    { "value": "Presidente Hayes", "label": "Presidente Hayes" },
    { "value": "San Pedro", "label": "San Pedro" }
  ],
  "Peru": [
    { "value": "Amazonas", "label": "Amazonas" },
    { "value": "Áncash", "label": "Áncash" },
    { "value": "Apurímac", "label": "Apurímac" },
    { "value": "Arequipa", "label": "Arequipa" },
    { "value": "Ayacucho", "label": "Ayacucho" },
    { "value": "Cajamarca", "label": "Cajamarca" },
    { "value": "Callao", "label": "Callao" },
    { "value": "Cusco", "label": "Cusco" },
    { "value": "Huancavelica", "label": "Huancavelica" },
    { "value": "Huánuco", "label": "Huánuco" },
    { "value": "Ica", "label": "Ica" },
    { "value": "Junín", "label": "Junín" },
    { "value": "La Libertad", "label": "La Libertad" },
    { "value": "Lambayeque", "label": "Lambayeque" },
    { "value": "Lima", "label": "Lima" },
    { "value": "Loreto", "label": "Loreto" },
    { "value": "Madre de Dios", "label": "Madre de Dios" },
    { "value": "Moquegua", "label": "Moquegua" },
    { "value": "Pasco", "label": "Pasco" },
    { "value": "Piura", "label": "Piura" },
    { "value": "Puno", "label": "Puno" },
    { "value": "San Martín", "label": "San Martín" },
    { "value": "Tacna", "label": "Tacna" },
    { "value": "Tumbes", "label": "Tumbes" },
    { "value": "Ucayali", "label": "Ucayali" }
  ],
  "Suriname": [
    { "value": "Brokopondo", "label": "Brokopondo" },
    { "value": "Commewijne", "label": "Commewijne" },
    { "value": "Marowijne", "label": "Marowijne" },
    { "value": "Nickerie", "label": "Nickerie" },
    { "value": "Para", "label": "Para" },
    { "value": "Paramaribo", "label": "Paramaribo" },
    { "value": "Saramacca", "label": "Saramacca" },
    { "value": "Sipaliwini", "label": "Sipaliwini" },
    { "value": "Wanica", "label": "Wanica" }
  ],
  "Uruguay": [
    { "value": "Canelones", "label": "Canelones" },
    { "value": "Cerro Largo", "label": "Cerro Largo" },
    { "value": "Colonia", "label": "Colonia" },
    { "value": "Durazno", "label": "Durazno" },
    { "value": "Flores", "label": "Flores" },
    { "value": "Florida", "label": "Florida" },
    { "value": "Lavalleja", "label": "Lavalleja" },
    { "value": "Maldonado", "label": "Maldonado" },
    { "value": "Montevideo", "label": "Montevideo" },
    { "value": "Paysandú", "label": "Paysandú" },
    { "value": "Río Negro", "label": "Río Negro" },
    { "value": "Rivera", "label": "Rivera" },
    { "value": "Rocha", "label": "Rocha" },
    { "value": "Salto", "label": "Salto" },
    { "value": "San José", "label": "San José" },
    { "value": "San Salvador", "label": "San Salvador" },
    { "value": "Soriano", "label": "Soriano" },
    { "value": "Tacuarembó", "label": "Tacuarembó" },
    { "value": "Treinta y Tres", "label": "Treinta y Tres" }
  ],
  "Venezuela": [
    { "value": "Amazonas", "label": "Amazonas" },
    { "value": "Anzoátegui", "label": "Anzoátegui" },
    { "value": "Apure", "label": "Apure" },
    { "value": "Aragua", "label": "Aragua" },
    { "value": "Barinas", "label": "Barinas" },
    { "value": "Bolívar", "label": "Bolívar" },
    { "value": "Carabobo", "label": "Carabobo" },
    { "value": "Cojedes", "label": "Cojedes" },
    { "value": "Delta Amacuro", "label": "Delta Amacuro" },
    { "value": "Falcón", "label": "Falcón" },
    { "value": "Guárico", "label": "Guárico" },
    { "value": "La Guaira", "label": "La Guaira" },
    { "value": "Lara", "label": "Lara" },
    { "value": "Mérida", "label": "Mérida" },
    { "value": "Miranda", "label": "Miranda" },
    { "value": "Monagas", "label": "Monagas" },
    { "value": "Nueva Esparta", "label": "Nueva Esparta" },
    { "value": "Portuguesa", "label": "Portuguesa" },
    { "value": "Sucre", "label": "Sucre" },
    { "value": "Táchira", "label": "Táchira" },
    { "value": "Trujillo", "label": "Trujillo" },
    { "value": "Yaracuy", "label": "Yaracuy" },
    { "value": "Zulia", "label": "Zulia" }
  ],
  "United States": [
    { "value": "Alabama", "label": "Alabama" },
    { "value": "Alaska", "label": "Alaska" },
    { "value": "Arizona", "label": "Arizona" },
    { "value": "Arkansas", "label": "Arkansas" },
    { "value": "California", "label": "California" },
    { "value": "Colorado", "label": "Colorado" },
    { "value": "Connecticut", "label": "Connecticut" },
    { "value": "Delaware", "label": "Delaware" },
    { "value": "Florida", "label": "Florida" },
    { "value": "Georgia", "label": "Georgia" },
    { "value": "Hawaii", "label": "Hawaii" },
    { "value": "Idaho", "label": "Idaho" },
    { "value": "Illinois", "label": "Illinois" },
    { "value": "Indiana", "label": "Indiana" },
    { "value": "Iowa", "label": "Iowa" },
    { "value": "Kansas", "label": "Kansas" },
    { "value": "Kentucky", "label": "Kentucky" },
    { "value": "Louisiana", "label": "Louisiana" },
    { "value": "Maine", "label": "Maine" },
    { "value": "Maryland", "label": "Maryland" },
    { "value": "Massachusetts", "label": "Massachusetts" },
    { "value": "Michigan", "label": "Michigan" },
    { "value": "Minnesota", "label": "Minnesota" },
    { "value": "Mississippi", "label": "Mississippi" },
    { "value": "Missouri", "label": "Missouri" },
    { "value": "Montana", "label": "Montana" },
    { "value": "Nebraska", "label": "Nebraska" },
    { "value": "Nevada", "label": "Nevada" },
    { "value": "New Hampshire", "label": "New Hampshire" },
    { "value": "New Jersey", "label": "New Jersey" },
    { "value": "New Mexico", "label": "New Mexico" },
    { "value": "New York", "label": "New York" },
    { "value": "North Carolina", "label": "North Carolina" },
    { "value": "North Dakota", "label": "North Dakota" },
    { "value": "Ohio", "label": "Ohio" },
    { "value": "Oklahoma", "label": "Oklahoma" },
    { "value": "Oregon", "label": "Oregon" },
    { "value": "Pennsylvania", "label": "Pennsylvania" },
    { "value": "Rhode Island", "label": "Rhode Island" },
    { "value": "South Carolina", "label": "South Carolina" },
    { "value": "South Dakota", "label": "South Dakota" },
    { "value": "Tennessee", "label": "Tennessee" },
    { "value": "Texas", "label": "Texas" },
    { "value": "Utah", "label": "Utah" },
    { "value": "Vermont", "label": "Vermont" },
    { "value": "Virginia", "label": "Virginia" },
    { "value": "Washington", "label": "Washington" },
    { "value": "West Virginia", "label": "West Virginia" },
    { "value": "Wisconsin", "label": "Wisconsin" },
    { "value": "Wyoming", "label": "Wyoming" }
  ],
  "Canada": [
    { "value": "Alberta", "label": "Alberta" },
    { "value": "British Columbia", "label": "British Columbia" },
    { "value": "Manitoba", "label": "Manitoba" },
    { "value": "New Brunswick", "label": "New Brunswick" },
    { "value": "Newfoundland and Labrador", "label": "Newfoundland and Labrador" },
    { "value": "Northwest Territories", "label": "Northwest Territories" },
    { "value": "Nova Scotia", "label": "Nova Scotia" },
    { "value": "Nunavut", "label": "Nunavut" },
    { "value": "Ontario", "label": "Ontario" },
    { "value": "Prince Edward Island", "label": "Prince Edward Island" },
    { "value": "Quebec", "label": "Quebec" },
    { "value": "Saskatchewan", "label": "Saskatchewan" },
    { "value": "Yukon", "label": "Yukon" }
  ],
  "Mexico": [
    { "value": "Aguascalientes", "label": "Aguascalientes" },
    { "value": "Baja California", "label": "Baja California" },
    { "value": "Baja California Sur", "label": "Baja California Sur" },
    { "value": "Campeche", "label": "Campeche" },
    { "value": "Chiapas", "label": "Chiapas" },
    { "value": "Chihuahua", "label": "Chihuahua" },
    { "value": "Coahuila", "label": "Coahuila" },
    { "value": "Colima", "label": "Colima" },
    { "value": "Durango", "label": "Durango" },
    { "value": "Guanajuato", "label": "Guanajuato" },
    { "value": "Guerrero", "label": "Guerrero" },
    { "value": "Hidalgo", "label": "Hidalgo" },
    { "value": "Jalisco", "label": "Jalisco" },
    { "value": "Mexico State", "label": "Mexico State" },
    { "value": "Mexico City", "label": "Mexico City" },
    { "value": "Michoacán", "label": "Michoacán" },
    { "value": "Morelos", "label": "Morelos" },
    { "value": "Nayarit", "label": "Nayarit" },
    { "value": "Nuevo León", "label": "Nuevo León" },
    { "value": "Oaxaca", "label": "Oaxaca" },
    { "value": "Puebla", "label": "Puebla" },
    { "value": "Querétaro", "label": "Querétaro" },
    { "value": "Quintana Roo", "label": "Quintana Roo" },
    { "value": "San Luis Potosí", "label": "San Luis Potosí" },
    { "value": "Sinaloa", "label": "Sinaloa" },
    { "value": "Sonora", "label": "Sonora" },
    { "value": "Tabasco", "label": "Tabasco" },
    { "value": "Tamaulipas", "label": "Tamaulipas" },
    { "value": "Tlaxcala", "label": "Tlaxcala" },
    { "value": "Veracruz", "label": "Veracruz" },
    { "value": "Yucatán", "label": "Yucatán" },
    { "value": "Zacatecas", "label": "Zacatecas" }
  ],
  "Brazil": [
    { "value": "Acre", "label": "Acre" },
    { "value": "Alagoas", "label": "Alagoas" },
    { "value": "Amapá", "label": "Amapá" },
    { "value": "Amazonas", "label": "Amazonas" },
    { "value": "Bahia", "label": "Bahia" },
    { "value": "Ceará", "label": "Ceará" },
    { "value": "Distrito Federal", "label": "Distrito Federal" },
    { "value": "Espírito Santo", "label": "Espírito Santo" },
    { "value": "Goiás", "label": "Goiás" },
    { "value": "Maranhão", "label": "Maranhão" },
    { "value": "Mato Grosso", "label": "Mato Grosso" },
    { "value": "Mato Grosso do Sul", "label": "Mato Grosso do Sul" },
    { "value": "Minas Gerais", "label": "Minas Gerais" },
    { "value": "Pará", "label": "Pará" },
    { "value": "Paraíba", "label": "Paraíba" },
    { "value": "Paraná", "label": "Paraná" },
    { "value": "Pernambuco", "label": "Pernambuco" },
    { "value": "Piauí", "label": "Piauí" },
    { "value": "Rio de Janeiro", "label": "Rio de Janeiro" },
    { "value": "Rio Grande do Norte", "label": "Rio Grande do Norte" },
    { "value": "Rio Grande do Sul", "label": "Rio Grande do Sul" },
    { "value": "Rondônia", "label": "Rondônia" },
    { "value": "Roraima", "label": "Roraima" },
    { "value": "Santa Catarina", "label": "Santa Catarina" },
    { "value": "São Paulo", "label": "São Paulo" },
    { "value": "Sergipe", "label": "Sergipe" },
    { "value": "Tocantins", "label": "Tocantins" }
  ]
}