import header from "../../assets/arrow-header.png";
import footer from "../../assets/arrow-footer.png";
import down from "../../assets/arrow-down.png";
import right from "../../assets/arrow-right.png";
import avatar from "../../assets/trend-avatar.png";
import "./Tendencias.css"

function Tendencias({ tendenciasData }) {
  const levels = [
    "Pre-Entry",
    "Entry",
    "Proficient",
    "Manager",
    "Líder",
    "Chief",
  ];


  let level = 1
  levels.map((level, index) => {
    console.log(level, index)
    if (level === tendenciasData.optionYears) {
      level = index + 1
    }
  })

  const sampleData = {
    profile: "Autónomo",
    currentLevel: level,
    lifestyle:
      "Generalmente dedicados a actividades de auto-empleo o autonomía dentro de las empresas, tienen un largo historial de auto-aprendizaje, en donde no necesitan guías ni maestros para aprender nuevas habilidades.",
    personality:
      "Práctica y acertada, tienen la capacidad de identificar oportunidades y los conocimientos que necesitan desarrollar, y de aprenderlos sin necesitar ayuda externa.",
    personalChallenges: [
      "Mantenerse motivado y enfocado en el desarrollo continuo de habilidades, incluso cuando no hay una estructura externa de apoyo.",
      "Gestionar el tiempo de manera eficiente para equilibrar el aprendizaje de nuevas habilidades con otras responsabilidades personales y profesionales.",
      "Asegurarse de mantener un equilibrio saludable entre la búsqueda de nuevas oportunidades y el disfrute de la vida personal.",
    ],
    professionalChallenges: [
      "Evitar la complacencia y la autosatisfacción al identificar y aprovechar oportunidades, manteniendo una mentalidad de crecimiento constante.",
      "Demostrar el valor de las habilidades aprendidas de forma autodidacta en un entorno profesional, especialmente si no se cuenta con certificaciones o credenciales formales.",
      "Superar posibles obstáculos o resistencia en el lugar de trabajo al implementar nuevas ideas y conocimientos adquiridos de manera independiente.",
    ],
    workStyles: [
      {
        title: "AFINIDAD CON EL AUTOEMPLEO",
        startLabel: "Empleado",
        endLabel: "Emprendedor",
        start: tendenciasData.optionEmprendedor.type === "Emprendedor" ? tendenciasData.optionEmprendedor.percentage : 100,
        end: tendenciasData.optionEmprendedor.type === "Empleado" ? tendenciasData.optionEmprendedor.percentage : 100,
      },
      {
        title: "AFINIDAD CON LA AUTONOMÍA LABORAL",
        startLabel: "Autonomía",
        endLabel: "Supervisión",
        start: tendenciasData.optionAutonomia.type === "Supervisión" ? tendenciasData.optionAutonomia.percentage : 100,
        end: tendenciasData.optionAutonomia.type === "Autonomía" ? tendenciasData.optionAutonomia.percentage : 100,
      },
      {
        title: "PRIORIDADES PARA ELEGIR UN TRABAJO",
        startLabel: "Conveniencia",
        endLabel: "Significativo",
        start: tendenciasData.optionPasionVsDinero.type === "Conveniencia" ? tendenciasData.optionPasionVsDinero.percentage : 100,
        end: tendenciasData.optionPasionVsDinero.type === "Significativo" ? tendenciasData.optionPasionVsDinero.percentage : 100,
      },
      {
        title: "PREFERENCIA DE ESTILO DE TRABAJO",
        startLabel: "Presencial",
        endLabel: "Remoto",
        start: tendenciasData.optionModalidad.type === "Presencial" ? tendenciasData.optionModalidad.percentage : 100,
        end: tendenciasData.optionModalidad.type === "Remoto" ? tendenciasData.optionModalidad.percentage : 100,
      },
    ],
  };


  const chart = [];

  levels.forEach((level, index) => {
    // Case 1: Current index is below user's level
    if (index + 1 < sampleData.currentLevel) {
      console.log("Current index is below user's level")
      chart.push(
        <div className="chart-below">
          <span>{level}</span>
          <div className="chart-index">
            <p>{index + 1}</p>
          </div>
          <div className={`chart-line line-${(index + 1)}`}></div>
          <div className="chart-dot"></div>
        </div>
      );
    }

    // Case 2: Current index is equal to user's level
    if (index + 1 === sampleData.currentLevel) {
      console.log("Current index is equal user's level")
      chart.push(
        <div className="chart-equal">
          <img className="current-arrow" src={down} alt="" />
          <span className="current-label">actual</span>
          <span>{level}</span>
          <div className="chart-index">
            <p>{index + 1}</p>
          </div>
          <div className={`chart-line line-${(index + 1)}`}></div>
          <div className="chart-dot"></div>
        </div>
      );
    }

    // Case 3: Current index is grater than user's level
    if (index + 1 > sampleData.currentLevel) {
      console.log("Current index is greater than user's level")
      chart.push(
        <div className="chart-above">
          <img className="above-arrow" src={right} alt="" />
          <span>{level}</span>
          <div className="chart-index">
            <p>{index + 1}</p>
          </div>
          <div className={`chart-line line-${(index + 1)}`}></div>
          <div className="chart-dot"></div>
        </div>
      );
    }
  });

  const styles = [];

  sampleData.workStyles.forEach((style) => {
    // Case 1: the start value of the spectrum is greater than the end value
    console.log(style)
    if (style.start > style.end) {
      styles.push(
        <div className="trend-item">
          <h4>{style.title}</h4>
          <div className="trend-data">
            <p className="high-label">{style.startLabel}</p>
            <div className="trend-bar">
              <div className="bar-wrapper">
                <div
                  className="bar-start"
                  style={{ width: `${style.start}%` }}
                ></div>
              </div>

              <div className="bar-wrapper">
                <div
                  className="bar-end"
                  style={{ width: `${style.end}%` }}
                ></div>
              </div>
            </div>
            <p className="low-label">{style.endLabel}</p>
          </div>
        </div>
      );
    }

    // Case 2: the start and end values of the spectrum are the same
    if (style.start === style.end) {
      styles.push(
        <div className="trend-item">
          <h4>{style.title}</h4>
          <div className="trend-data">
            <p className="high-label">{style.startLabel}</p>
            <div className="trend-bar">
              <div className="bar-wrapper">
                <div
                  className="bar-start"
                  style={{ width: `${style.start}%` }}
                ></div>
              </div>

              <div className="bar-wrapper">
                <div
                  className="bar-end"
                  style={{ width: `${style.end}%` }}
                ></div>
              </div>
            </div>
            <p className="high-label">{style.endLabel}</p>
          </div>
        </div>
      );
    }

    // Case 3: the start value of the spectrum is less than the end value
    if (style.start < style.end) {
      styles.push(
        <div className="trend-item">
          <h4>{style.title}</h4>
          <div className="trend-data">
            <p className="low-label">{style.startLabel}</p>
            <div className="trend-bar">
              <div className="bar-wrapper">
                <div
                  className="bar-start"
                  style={{ width: `${style.start}%` }}
                ></div>
              </div>

              <div className="bar-wrapper">
                <div
                  className="bar-end"
                  style={{ width: `${style.end}%` }}
                ></div>
              </div>
            </div>
            <p className="high-label">{style.endLabel}</p>
          </div>
        </div>
      );
    }
  });

  const personalChallenges = [];

  sampleData.personalChallenges.forEach((challenge) => {
    personalChallenges.push(<li>{challenge}</li>);
  });

  const professionalChallenges = [];

  sampleData.professionalChallenges.forEach((challenge) => {
    professionalChallenges.push(<li>{challenge}</li>);
  });

  return (
    <div className="tendencias-layout">
      <img className="tendencias-header" src={header} alt="" />
      <h2>Tendencias aspiraciones profesionales</h2>
      <div className="tendencias-columns">
        <div className="tendencias-profile">
          <img className="tendencias-avatar" src={avatar} alt={sampleData.profile} />
          <h4>Tu perfil es</h4>
          <h3>{tendenciasData.optionArquetipo}</h3>
          <div className="tendencias-chart">{chart}</div>
        </div>
        <div className="tendencias-styles">
          <h3>Estilo de trabajo</h3>
          {styles}
        </div>
      </div>
      <div className="tendencias-info">
        <div className="tendencias-text">
          <h4>Estilo de vida</h4>
          <p>{sampleData.lifestyle}</p>
        </div>
        <div className="tendencias-text">
          <h4>Personalidad</h4>
          <p>{sampleData.personality}</p>
        </div>
        <div className="tendencias-text">
          <h4>Retos Personales</h4>
          <ol>{personalChallenges}</ol>
        </div>
        <div className="tendencias-text">
          <h4>Retos Profesionales</h4>
          <ol>{professionalChallenges}</ol>
        </div>
      </div>
      <img className="tendencias-footer" src={footer} alt="" />
    </div>
  );
}

export default Tendencias;