import * as React from "react";
import "./Popup.css";
import Box from "@mui/material/Box";
import Button from "../Button/Button";
import Modal from "@mui/material/Modal";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import env from "react-dotenv";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#26D07C",
  color: "#fff",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
  letterSpacing: "0.5px",
  padding: "48px",
};
const url = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL 
  : "http://localhost:3501";

export default function Popup(props) {
  const url = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "http://localhost:3501";
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const handleOpen = () => {
    setOpen(true);
    setLoader(true);

    // Get previously submitted answers
    //console.log(props.user);
    //console.log(props.step);

    //Get last block
    axios.get(`${url}/modules/get-blocks/${props.step}`).then((response) => {
      axios
        .get(
          `${url}/answers/get-answers/${props.user}/${
            response.data.data.blocks[response.data.data.blocks.length - 1]
              .id_block
          }`
        )
        .then((response) => {
          if (response.data.data.answers.length > 0) {
            // Answers found!
            console.log("answers found");
            // Check if props answers match stored answers
            if(props.answers){ 
              // Answers have been modified
              // API call
              setLoader(false);
            } else {
              // No new answers
              setLoader(false);
            }
          } else {
            // Submit final answers
            axios
              .post(url + "/answers/add-answer", { answers: props.answers, source: "life-design" })
              .then((response) => {
                setLoader(false);
              })
              .catch((error) => console.log(error));
          }
        });
    });
  };
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
        type="filled"
        corners="rounded"
        text="Finalizar"
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="¡Haz terminado!"
        aria-describedby="Da click en el siguiente enlace para continuar con tu progreso hacia tu futuro profesional."
      >
        <Box sx={style}>
          {loader === true && (
            <>
              <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="var(--primary)"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </>
          )}
          {loader === false && (
            <>
              <div className="paralelogram">
              <h2>¡Felicidades por completar el taller de Life Design en Pivotea! 🌟</h2>
              </div>
              <p className="description">
                Has dado un paso importante hacia la creación de la vida que deseas. Recuerda que el viaje del aprendizaje es continuo y lleno de oportunidades emocionantes. Sigue explorando, descubriendo y creciendo. Estamos aquí para apoyarte en cada paso del camino. ¡Adelante y que tu aprendizaje te lleve hacia un futuro brillante y lleno de éxito! 💪🚀
              </p>
              <a href="/proyecto-de-aprendizaje" className="cta">
                Ir al Proyecto de Aprendizaje
              </a>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
