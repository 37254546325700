import diary from "./DigitalDiary.module.css"; 

import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

import { Button, Form } from "../../components";

const DigitalDiary = () => {
    const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(true);
    const [answers, setAnswers] = useState([]);
    const [entries, setEntries] = useState([]);
    const [currentEntry, setCurrentEntry] = useState(null);

    const lastEntryIndex = Object.keys(entries).length - 1;
    const lastEntry = entries[Object.keys(entries)[lastEntryIndex]];

    const isLastEntryThisWeek = () => {
        const today = new Date();
        const todayWeek = today.getFullYear().toString() + today.getMonth().toString() + today.getDate().toString();
        const entryDate = lastEntry ? new Date(lastEntry[0].created_at) : null;
        const entryWeek = entryDate ? entryDate.getFullYear().toString() + entryDate.getMonth().toString() + entryDate.getDate().toString() : null;
        return entryWeek ? todayWeek === entryWeek : false;
    }

    const url = process.env.REACT_APP_API_URL
        ? process.env.REACT_APP_API_URL
        : "http://localhost:3501";

    const survey = [
        {
            "id_question": "DDQ1",
            "question_label": "1. ¿Cuál es tu nivel de motivación para cumplir los objetivos en el programa esta semana?",
            "question_type": "radio_single_choice",
            "choices": [
                {
                    "id_choice": "DDQ1C1",
                    "choice_label": "Muy desmotivado"
                },
                {
                    "id_choice": "DDQ1C2",
                    "choice_label": "Desmotivado"
                },
                {
                    "id_choice": "DDQ1C3",
                    "choice_label": "Neutral"
                },
                {
                    "id_choice": "DDQ1C4",
                    "choice_label": "Motivado"
                },
                {
                    "id_choice": "DDQ1C5",
                    "choice_label": "Altamente motivado"
                }
            ]
        },
        {
            "id_question": "DDQ2",
            "question_label": "¿Por qué?",
            "question_type": "text-large"
        },
        {
            "id_question": "DDQ3",
            "question_label": "2. Escribe el número de tareas que proyectaste para esta semana para acercarte a tu transición profesional",
            "question_type": "text-large"
        },
        {
            "id_question": "DDQ4",
            "question_label": "3. De lo proyectado para esta semana, ¿Cuántas tareas realizaste?",
            "question_type": "text-large"
        },
        {
            "id_question": "DDQ5",
            "question_label": "4. ¿Cuánto tiempo a la semana has invertido en realizar estas tareas?",
            "question_type": "text-large"
        },
        {
            "id_question": "DDQ6",
            "question_label": "5. ¿Qué podrías mejorar la siguiente semana para cumplir con tus objetivos del programa?",
            "question_type": "text-large"
        }
    ]

    const getEntries = () => {
        axios.get(`${url}/answers/get-digital-diary-answers/${authUser?.id}`)
            .then((response) => {
                setEntries(response.data.data.entries);
            })
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        const storedUser = Cookies.get("authUser");
        if (storedUser) {
            const user = JSON.parse(storedUser);
            if (user && user.token) {
                setAuthUser(user);
                setIsLoggedIn(true);
            } else {
                setAuthUser(null);
                setIsLoggedIn(false);
                navigate("/login");
            }
        } else {
            navigate("/login");
        }
    }, [setAuthUser, setIsLoggedIn, navigate]);

    useEffect(() => {
        if (authUser) {
            getEntries();
        }
    }, [authUser])

    const formCallback = (ans) => {
        setAnswers(ans);
        if (ans.length === survey.length && !isLastEntryThisWeek()) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }

    const handleSubmit = () => {
        setDisabled(true);
        axios.post(`${url}/answers/add-answer`, { answers: answers, source: "digital-diary" })
            .then((response) => {
                getEntries();
            })
            .catch((error) => console.error(error));

        setAnswers([]);
    }

    return (
        <div className={diary.digitalDiary}>
            <div className={diary.diaryCol25}>
                <div className={diary.entries}>
                    <div className={`${diary.diaryTab} ${currentEntry === null ? diary.activeDiaryTab : ''}`} onClick={() => setCurrentEntry(null)}>
                        Nueva Entrada
                    </div>
                    {entries && Object.keys(entries)?.map((entry, index) => {
                        return (
                            <div key={index} className={`${diary.diaryTab} ${currentEntry && currentEntry[0]?.id_answer === entries[entry][0].id_answer ? diary.activeDiaryTab : ''}`} onClick={() => setCurrentEntry(entries[entry])}>
                                Entrada {entry}
                            </div>
                        )
                    })} 
                </div>
            </div>
            <div className={diary.diaryCol75}>
                <h1>Diario Digital</h1>
                <p className={diary.diaryParagraph}>Este diario sirve para que puedas hacer un balance semanal de tu avance en el programa Pivotea hacia tu transformación profesional.</p>
                <p className={diary.diaryNote}>* Solo puedes subir una entrada por semana.</p>
                {currentEntry === null ? (
                    <Form
                    survey={survey}
                    block={1}
                    user={authUser?.id}
                    callback={formCallback}
                />
                ) : (
                    <div className="current-entry">
                        {currentEntry.map((question, index) => {
                            return (
                                <div className="entry-question" key={index}>
                                    <h4>{question.question}</h4>
                                    <p>{question.answer}</p>
                                </div>
                            )
                        })}
                    </div>
                )}
                {isLastEntryThisWeek() && (
                    <p className={diary.diaryNote}>Ya has subido una entrada esta semana.</p>
                )}
                <Button
                    className={diary.submitButton}
                    type={disabled ? "disabled" : "filled"}
                    corners="rounded"
                    text="Subir"
                    disabled={disabled}
                    onClick={() =>
                        handleSubmit()
                    }
                />
            </div>

        </div>
    );
}

export default DigitalDiary;