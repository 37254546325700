import css from './FieldSelectInput.module.css';

const FieldSelectInput = (props) => {
        
            const { 
                label,
                name,
                options,
                value,
                onChange,
                className
            } = props;
    
            return (
                <div className={`${css.container} ${className}`}>
                    <label className={css.label} htmlFor={name}>{label}</label>
                    <select 
                        className={css.select} 
                        name={name} 
                        id={name} 
                        value={value} 
                        onChange={onChange} 
                    >
                        {options.map((option, index) => (
                            <option 
                                key={index} 
                                value={option.value}
                                {...(option.hidden && { hidden: true })}
                            >{option.label}</option>
                        ))}
                    </select>
                </div>
            )   
}

export default FieldSelectInput;
