import React, { useState } from 'react';
import './TestimonialCarousel.css';
import arrowLeft from '../../assets/Arrow-Left.svg';
import arrowRight from '../../assets/Arrow-Right.svg';

const TestimonialCarousel = ({ testimonials }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const testimonialsPerPage = 3;

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? testimonials.length - testimonialsPerPage : prevSlide - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === testimonials.length - testimonialsPerPage ? 0 : prevSlide + 1));
  };

  const visibleTestimonials = testimonials.slice(currentSlide, currentSlide + testimonialsPerPage);

  return (
    <div className="testimonial-carousel">
      <div className="carousel-container">
        {visibleTestimonials.map((testimonial, index) => (
          <div className="testimonial" key={index}>
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <h3 className="testimonial-name">{testimonial.name}</h3>
          </div>
        ))}
      </div>
      <div className="carousel-controls">
        <button className="btn-prev" onClick={handlePrevSlide}>
          <img src={arrowLeft} alt="Previous" />
        </button>
        <button className="btn-next" onClick={handleNextSlide}>
          <img src={arrowRight} alt="Next" />
        </button>
      </div>
    </div>
  );
};

export default TestimonialCarousel;