import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Cookies from "js-cookie";

import Button from "../../components/Button/Button";

import styles from "./LandingPage.module.css";
import home from "../Home/Home.module.css";

import pivotea from "../../assets/newsletter.png";
import hero from "../../assets/video.png";
import icon_base from "../../assets/icon_base.png";
import icon_dark from "../../assets/icon_dark.png";
import icon_light from "../../assets/icon_light.png";
import first from "../../assets/1.png";
import second from "../../assets/2.png";
import third from "../../assets/3.png";
import fourth from "../../assets/4.png";
import fifth from "../../assets/5.png";

const LandingPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const { setAuthUser, isLoggedIn, setIsLoggedIn, hasPurchase } = useAuth();

    useEffect(() => {
        if (!isLoggedIn) {
            const authCookie = Cookies.get("authUser");
            if (authCookie) {
                console.log("Login found in cookies");
                const user = JSON.parse(authCookie);
                setAuthUser(user);
                setIsLoggedIn(true);
            } else {

            }
        }
    }, [isLoggedIn, setAuthUser, setIsLoggedIn, navigate]);

    const subscribe = () => {
        console.log("Función de email pendiente de elaboración");
    };

    return (
        <div className={styles.landing}>
            <div className={styles.landingHero}>
                <div className={styles.landingHeroText}>
                    <h1>LA INCUBADORA DE LA SIGUIENTE ETAPA DE TU VIDA</h1>
                    <p>
                        Descubre tu propósito y transforma tus sueños en logros profesionales.
                    </p>
                    <Button
                        type="outline"
                        corners="rounded"
                        text={hasPurchase ? "Iniciar" : "Consigue Pivotea"}
                        size="md"
                        onClick={() => {
                            if (hasPurchase) {
                                navigate("/miespacio");
                            } else {
                                navigate("/checkout");
                            }
                        }}
                    />
                </div>
                <div className={styles.landingHeroImage}>
                    <img src={hero} alt="Pivotea" />
                </div>
            </div>
            <div className={styles.landingDescription}>
                <strong>PIVOTEA</strong> es un programa de aprendizaje personalizado que ofrece mentorías diseñado para adquieras y mejores las habilidades que te ayudarán en tu siguiente paso profesional. ¡Descubre tu trayectoria ideal profesional y accede a contenidos de calidad con una membresía del Skilling Center de Tecmilenio para desarrollar tus habilidades!*
            </div>
            <div className={styles.landingSeparator}>
                Vivirás esta experiencia teniendo 3 pilares en mente, los cuáles te ayudarán a: reflexionar, dirigir y tomar acción.
            </div>
            <div className={styles.landingTools}>
                <div className={styles.landingTool}>
                    <img src={icon_light} alt="Reflexionar" />
                    <h4>VOCACIONAMIENTO</h4>
                    <p>
                        Aquí definirás tus sueños y objetivos descubriendo tu propósito de vida.
                    </p>
                </div>
                <div className={styles.landingTool}>
                    <img src={icon_dark} alt="Reflexionar" />
                    <h4>ACOMPAÑAMIENTO</h4>
                    <p>
                        Generarás planes de acción concretos con coaching profesional.
                    </p>
                </div>
                <div className={styles.landingTool}>
                    <img src={icon_base} alt="Reflexionar" />
                    <h4>FORMACIÓN</h4>
                    <p>
                        Fortalecerás tu perfil profesional con la mejor oferta educativa.
                    </p>
                </div>
            </div>
            <div className={styles.landingCta}>
                <div className={styles.landingCtaText}>
                    <p>EN TAN SÓLO 3 MESES:</p>
                    <h4>¡TRANSFORMA TUS SUEÑOS <br />EN LOGROS PROFESIONALES!</h4>
                </div>
                <div className={styles.landingCtaButton}>
                    <Button
                        type="outline"
                        corners="rounded"
                        text={
                            !isLoggedIn
                                ? 'Regístrate →'
                                : hasPurchase
                                    ? "Ir a Mi Espacio →"
                                    : "Comprar Experiencia →"
                        }
                        size="md"
                        onClick={() => {
                            if (!isLoggedIn) {
                                navigate("/signup"); // Si no está logueado
                            } else if (isLoggedIn && !hasPurchase) {
                                navigate("/checkout"); // Si está logueado pero no tiene compra
                            } else if (isLoggedIn && hasPurchase) {
                                navigate("/miespacio"); // Si tiene una compra
                            }
                        }}
                    />
                </div>
            </div>
            <div className={styles.landingCards}>
                <h4>BENEFICIOS DE ESTAR EN PIVOTEA</h4>
                <div className={styles.landingCardWrapper}>
                    <div className={styles.landingCard}>
                        <img src={first} alt="Reflexionar" />
                        <p>Establecimiento de un proyecto de aprendizaje</p>
                    </div>
                    <div className={styles.landingCard}>
                        <img src={second} alt="Reflexionar" />
                        <p>Fortalecimiento de tu perfil profesional: propósito de vida, creación de CV, aplicaciones y entrevistas de trabajo</p>
                    </div>
                    <div className={styles.landingCard}>
                        <img src={third} alt="Reflexionar" />
                        <p>Una comunidad donde podrás inspirarte de las experiencias de los demás aprendedores</p>
                    </div>
                    <div className={styles.landingCard}>
                        <img src={fourth} alt="Reflexionar" />
                        <p>Generación de escenarios futuros para tu brújula profesional con el taller de Life Design</p>
                    </div>
                    <div className={styles.landingCard}>
                        <img src={fifth} alt="Reflexionar" />
                        <p>Insignias digitales al finalizar las rutas de formación que concluyas</p>
                    </div>
                </div>
            </div>
            <div className={home.newsletterContainer}>
                <div className={home.newsletterText}>
                    <h4>BIENVENID@ A PIVOTEA</h4>
                    <p>
                        Si tienes alguna duda, comentario o simplemente quieres saludar,
                        envía un correo a:{" "}
                        <a href="mailto:competencias@servicios.tecmilenio.mx" target="_blank" rel="noreferrer">
                            competencias@servicios.tecmilenio.mx
                        </a>
                    </p>
                </div>
                <div className={home.newsletterCta}>
                    <img src={pivotea} alt="Pivotea" />
                    <div className={home.newsletterForm}>
                        <h4>FORMA PARTE DE FUTUROS PILOTOS</h4>
                        <div>
                            <p>Comparte tu email con nosotros</p>
                            <input
                                type="email"
                                value={email}
                                placeholder="usuario@email.com"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </div>
                        <Button
                            type="outline"
                            corners="rounded"
                            text="Suscribirme"
                            size="sm"
                            onClick={subscribe}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPage