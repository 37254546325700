import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { FormLabel } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import "./CheckboxGroup.css"

export default function CheckboxGroup(props) {
  const selected = useRef([]);
  const [limit, setLimit] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [questions, setQuestions] = useState([]);

  const onCheck = (event) => {
    setHasChanged(true);
    if (event.target.checked) {
      // Add value
      selected.current.push(event.target.value);
      // Check if limit has been reached
      if (selected.current.length >= props.limit) {
        setLimit(5);
      }
    } else {
      // Remove value
      const index = selected.current.indexOf(event.target.value);
      selected.current.splice(index, 1);

      if (selected.current.length <= props.limit) {
        setLimit(limit - 1);
      }
    }
    props.callback({
      block_id: props.userData.block,
      user_id: props.userData.id,
      answer: selected.current,
      question_type: "checkbox_group",
      id_question: props.userData.id_question,
      question: props.userData.question,
    });
  };

  useEffect(() => {
    if(props.answer && !hasChanged) {
      selected.current = JSON.parse(props.answer);
      
      if (selected.current.length >= props.limit) {
        setLimit(true);
        let options = [];
        const columns = [];
        props.list.forEach((item) => {
          options.push(
            <FormLabel component="legend">{item.section_label}</FormLabel>
          );
          item.choices.forEach((op) => {
            if (selected.current.includes(op.choice_label)) {
              options.push(
                <FormControlLabel
                  control={
                    <Checkbox value={op.choice_label} checked onChange={onCheck} />
                  }
                  label={op.choice_label}
                />
              );
            } else {
              options.push(
                <FormControlLabel
                  control={
                    <Checkbox
                      value={op.choice_label}
                      disabled
                    />
                  }
                  label={op.choice_label}
                />
              );
            }
            
          });
          columns.push(<div>{options}</div>)
            options = [];
        });
        setQuestions(Array.from(columns));
      } else {
        let options = [];
        const columns = [];
        props.list.forEach((item) => {
          options.push(
            <FormLabel component="legend">{item.section_label}</FormLabel>
          );
          item.choices.forEach((op) => {
            if (selected.current.includes(op.choice_label)) {
              options.push(
                <FormControlLabel
                  control={
                    <Checkbox value={op.choice_label} checked onChange={onCheck} />
                  }
                  label={op.choice_label}
                />
              );
            } else {
              options.push(
                <FormControlLabel
                  control={
                    <Checkbox
                      value={op.choice_label}
                      onChange={onCheck}
                    />
                  }
                  label={op.choice_label}
                />
              );
            }
            
          });
          columns.push(<div>{options}</div>)
            options = [];
        });
        setQuestions(Array.from(columns));
      }

        
    } else {
      if (limit === 5) {
        let options = [];
        const columns = [];
        props.list.forEach((item) => {
          options.push(
            <FormLabel component="legend">{item.section_label}</FormLabel>
          );
          item.choices.forEach((op) => {
            if (selected.current.includes(op.choice_label)) {
              options.push(
                <FormControlLabel
                  control={
                    <Checkbox value={op.choice_label} onChange={onCheck} checked/>
                  }
                  label={op.choice_label}
                />
              );
            } else {
              options.push(
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      value={op.choice_label}
                      onChange={onCheck}
                    />
                  }
                  label={op.choice_label}
                />
              );
            }
            
          });
          columns.push(<div>{options}</div>)
            options = [];
        });
        setQuestions(Array.from(columns));
      } else {
        let options = [];
        const columns = [];
        props.list.forEach((item) => {
          options.push(
            <FormLabel component="legend">{item.section_label}</FormLabel>
          );
          item.choices.forEach((op) => {
            if (selected.current.includes(op.choice_label)) {
              options.push(
                <FormControlLabel
                  control={
                    <Checkbox value={op.choice_label} checked onChange={onCheck} />
                  }
                  label={op.choice_label}
                />
              );
            } else {
              options.push(
                <FormControlLabel
                  control={
                    <Checkbox
                      value={op.choice_label}
                      onChange={onCheck}
                    />
                  }
                  label={op.choice_label}
                />
              );
            }
          });
          columns.push(<div>{options}</div>)
          options = [];
        });
        setQuestions(Array.from(columns));
      }
    }
    
  }, [limit]);

  return (
    <FormControl
      component="fieldset"
      className="checkbox-group"
      sx={{
        display: "flex",
        flexFlow: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        gap: "1rem",
        flexWrap: "wrap",
      }}
    >
      {questions}
    </FormControl>
  );
}
