import React from 'react'
import "./EmptySidebar.css"

function EmptySidebar(props) {

  const {
    currentOption,
    options
  } = props

  const steps = []
  for (let i = 0; i < options.length; i++) {
    steps.push(
      <div className={`step step-${options[i].status} ${currentOption === options[i].id ? 'current' : ''}`} onClick={options[i].onClick}>
        <div className={`index index-${options[i].status}`}>{+i+1}</div>
        <p>{options[i].name}</p>
      </div>
    )
  }
  return (
    <div className='EmptySidebar'>
      <div className='EmptySidebar-title'>
        <p>{props.title}</p>
        <div className='EmptySidebar-buttons'>
        </div>
      </div>
      {steps}
      </div>
  )
}

export default EmptySidebar