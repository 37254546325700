import React, { useState } from "react";
import arrowDown from '../../assets/arrowDown.svg'
import arrowUP from '../../assets/arrowUp.svg'
import styles from '../../containers/LandingPageComercial2/LandingPageComercial2.module.css'

const Collapsible = (props) => {
    const {open, children, title} = props;
        const [isOpen, setIsOpen] = useState(open);
      
        const handleFilterOpening = () => {
          setIsOpen((prev) => !prev);
        }; 
    return (
        <div className={styles.itemFaq}>
            <div className={styles.titleFaq}>
                <div className={styles.title} onClick={handleFilterOpening}>{title} <img src={isOpen
              ? arrowDown
              : arrowUP } /></div>
            </div>
            {isOpen && (
            <div className={styles.collapsablFaq}>
                <h4>{children}</h4>
            </div>
            )}
        </div>
    );
  };
export default Collapsible;

