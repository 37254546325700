import styles from './LandingPageComercial.module.css';
import image from '../../assets/image-landing.jpg'
import Collapsible from '../../components/Collapsible/collapsibleShowHide'
import TestimonialCarousel from '../../components/TestimonialCarousel/TestimonialCarousel';

const LandingPageComercial = () => {
  const testimonials = [
    {
      name: "Participante Pivotea",
      quote: "Me encantó la flexibilidad del programa y cómo se adapta a mi vida en cualquier momento",
    },
    {
      name: "Participante Pivotea",
      quote: "Gracias a PIVOTEA, entendí que tengo el poder de planificar mi futuro, y ellos me brindaron las herramientas para hacerlo realidad.",
    },
    {
      name: "Participante Pivotea",
      quote: "Me permití imaginar un nuevo futuro, y hoy estoy viviendo esa visión.",
    },
    {
      name: "Participante Pivotea",
      quote: "Nunca había entendido la planificación de manera tan clara como lo hice en PIVOTEA. Ahora sé exactamente qué pasos tomar para alcanzar mis metas.",
    },
    {
      name: "Participante Pivotea",
      quote: "Desarrollé habilidades clave para construir el futuro que deseo.",
    },
    {
      name: "Participante Pivotea",
      quote: "Conseguí un nuevo trabajo, pero ese fue solo uno de los muchos logros que obtuve gracias a PIVOTEA.",
    },
  ];

  return (
    <div className={styles.landing}>
      <div className={styles.containerSection}>
        <div className={styles.containerText}>
          <h1 className={styles.titleSection}>
            Encuentra tu propósito de vida y comienza a transformar tu futuro profesional
          </h1>
          <p className={styles.subtitleSection}>
            Programa de 13 semanas basado en coaching y metodologías de design thinking para una transición laboral con un enfoque en bienestar personal.
          </p>
          <a target='_blank' href='https://es.surveymonkey.com/r/QBX7QC8'>
            <button className={styles.buyButton}>Quiero ser contactado</button>
          </a>
        </div>
        <div className={styles.containerImage}>
          <img src={image} className={styles.image} alt="Pivotea landing" />
        </div>
      </div>

      <div className={styles.programDescriptionSection}>
        <h1 className={styles.titleDescriptionSection}>Descripción del programa</h1>
        <div className={styles.subtitleDescriptionSection}>
          Durante 13 semanas (o 3 meses), explorarás las 3 etapas de Pivotea
        </div>
        <div className={styles.itemsSection}>
          <div className={styles.item}>
            <div className={styles.cardTitle}>Vocacionamiento</div>
            <div className={styles.cardText}>
              Haz cambios significativos y conviértete en la mejor versión de ti mismo
            </div>
            <div className={styles.cardText}>
              A través del autoconocimiento, Identifica y co-crea tu camino profesional y además
              descubrirás tu propósito de vida para alcanzar tu bienestar laboral y personal-
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.cardTitle}>Acompañamiento</div>
            <div className={styles.cardText}>
              Los coaches están aquí para ayudarte en tu crecimiento personal y profesional.
            </div>
            <div className={styles.cardText}>
              Coaches expertos te apoyarán en enfrentar los obstáculos y encontrar soluciones
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.cardTitle}>Formación</div>
            <div className={styles.cardText}>Construye confianza con conocimiento</div>
            <div className={styles.cardText}>
              Accede a las rutas de formación para fortalecer tu perfil profesional
            </div>
          </div>
        </div>
      </div>

      <div className={styles.testimoniosSection}>
        <h1 className={styles.teamCoachesTitle}>Personas que han vivido Pivotea</h1>
        <TestimonialCarousel testimonials={testimonials} />
      </div>

      <div className={styles.programContentSection}>
        <div className={styles.programContentTitle}>Lo que verás dentro del programa</div>

        <div className={styles.programCard}>
          <div className={styles.programTitle}>Mi viaje interior</div>
          <div className={styles.programText}>
            Evalúa tu portafolio de vida y tus aspiraciones personales
          </div>
        </div>

        <div className={styles.programCard}>
          <div className={styles.programTitle}>Mi impacto en el mundo</div>
          <div className={styles.programText}>
            Visualiza cómo tus pasiones e intereses pueden impactar al mundo
          </div>
        </div>

        <div className={styles.programCard}>
          <div className={styles.programTitle}>Mi propósito de vida</div>
          <div className={styles.programText}>
            Establece un propósito de vida que te permitirá alinear tus acciones
          </div>
        </div>

        <div className={styles.programCard}>
          <div className={styles.programTitle}>Mis preferencias laborales</div>
          <div className={styles.programText}>
            Determina cuáles son las condiciones de tu trabajo ideal.
          </div>
        </div>

        <div className={styles.programCardLast}>
          <div className={styles.programTitle}>Mi futuro profesional</div>
          <div className={styles.programText}>
            Diseña y descubre los diferentes caminos y posibles futuros para ti.
          </div>
        </div>
      </div>

      <div className={styles.teamCoachesSection}>
        <div className={styles.teamCoachesTitle}>Equipo de coaches</div>
        <div className={styles.teamCoachesGrid}>
          <div className={styles.teamCoachesGridItem}>
            <div className={styles.gridItemContainer}>
              <div className={styles.title}>
                Mónica Farrera
              </div>
              <div className={styles.paragraph}>
                Profesional orientada en intervenciones de bienestar corporativo, tiene una Maestría en Liderazgo Positivo por la Universidad Tecmilenio y es Coach Integral por HEBA, red global especializada en desarrollo humano
              </div>
            </div>
          </div>
          <div className={styles.teamCoachesGridItem}>
            <div className={styles.gridItemContainer}>
              <div className={styles.title}>
                Lucía Salas
              </div>
              <div className={styles.paragraph}>
                Como coach, consultora y asesora de líderes he participado en acompañamiento en proceso de formación de grupos directivos de colegios y fundaciones importantes así como procesos de Innovación en Chile, Ecuador, Colombia y Perú
              </div>
            </div>
          </div>
          <div className={styles.teamCoachesGridItem}>
            <div className={styles.gridItemContainer}>
              <div className={styles.title}>
                Claudia Ortega
              </div>
              <div className={styles.paragraph}>
                Psicóloga certificada como coach empresarial, maestría en educación, consultora en RRHH y psicoterapeuta humanista. Amplia experiencia en formación y desarrollo humano.
              </div>
            </div>
          </div>
          <div className={styles.teamCoachesGridItem}>
            <div className={styles.gridItemContainer}>
              <div className={styles.title}>
                Thatiana Villanueva
              </div>
              <div className={styles.paragraph}>
                Licenciada en psicología con maestría en educación, 5 años como capacitadora y tallerista para empresas y organizaciones por parte de Tecmilenio
              </div>
            </div>
          </div>
          <div className={styles.teamCoachesGridItem}>
            <div className={styles.gridItemContainer}>
              <div className={styles.title}>
                Ana Diz Jimenez
              </div>
              <div className={styles.paragraph}>
                Licenciada en comercio exterior y aduanas. Maestra en procesos de  desarrollo humano. Consultor Organizacional en Desarrollo Humano y Desarrollo Organizacional.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.bannerSection}>
        <div className={styles.banner}>
          <div className={styles.bannerSectionTitle}>Me interesa formar parte de Pivotea</div>
          <div className={styles.bannerSectionSubtitle}>Quiero ser contactado para conocer más del programa</div>
          <a target='_blank' href='https://es.surveymonkey.com/r/QBX7QC8'>
            <button className={styles.bannerSectionButton}>Quiero ser contactado</button>
          </a>
        </div>
      </div>

      <div className={styles.faqSection}>
        <div className={styles.faqTitle}>FAQ’s</div>
        <div className={styles.faqContainer}>
          <Collapsible
            open
            title="1. ¿Cuál es la duración del programa?"
            children="Pivotea tiene una duración estimada de 13 semanas o 3 meses, pero el ritmo es marcado por cada persona y sus necesidades."
          />
          <Collapsible
            title="2. ¿Tengo que asistir a sesiones sincrónicas?"
            children="Pivotea se basa en un sistema de acompañamiento por coaches, quienes se reunirán contigo de manera periodica según tus avances. Las sesiones tienen una duración entre 30-90 minutos."
          />
          <Collapsible
            title="3. ¿Cómo se si Pivotea es para mi?"
            children="Pivotea es para personas que se encuentran en un periodo de transición laboral o buscan un cambio en su profesión."
          />
          <Collapsible
            title="4. ¿Qué obtengo de Pivotea?"
            children="El programa se compone de un taller dividido en módulos del cual se desglosan las sesiones de coaching y un plan de trabajo de acuerdo a tus necesidades y aspiraciones."
          />
        </div>
      </div>
    </div>
  );
}

export default LandingPageComercial;
