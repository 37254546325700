import './Button.css'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

export default function Button(props) {
    let content = [];
    if(props.icon) {
        switch(props.icon) {
            case 'back':
                content.push(<ArrowBackIosNewRoundedIcon />)
                content.push(props.text);
                break;
            case 'next':
                content.push(props.text);
                content.push(<ArrowForwardIosRoundedIcon />)
                break;
            case 'link':
                content.push(props.text);
                content.push(<EastRoundedIcon />)
                break;
            default:
                content.push(<HelpOutlineRoundedIcon />)
                content.push(props.text);
                break;
        }
    } else {
        content.push(props.text);
    }
    
    return (
        <button onClick={props.onClick} className={`btn btn-${props.type} ${props.corners}-btn ${props.size} ${props.className}`} disabled={props.disabled}>
            {content}
        </button>
    )
}