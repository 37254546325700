import { FormControl, TextField, FormLabel } from "@mui/material";
import { useEffect, useState } from "react";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import IconButton from "@mui/material/IconButton";
import "./TextGroup.css";

export default function TextGroup(props) {
  // Props -> min (number): minimum rows
  // Props -> max (number): maximum rows

  const [fields, setFields] = useState([]);
  const [icon, setIcon] = useState(<AddCircleOutlineRoundedIcon />);
  useEffect(() => {
    const newFields = [];
    for (let i = 0; i < props.min; i++) {
      newFields.push(
        <TextField
          id={`C${i + 1}`}
          placeholder="Escribe aquí..."
          variant="outlined"
        />
      );
    }
    setFields(newFields);
  }, []);

  const onChange = () => {
    if(fields.length < props.max) {
      //ADD
      const currentFields = [...fields];
      currentFields.push(<TextField
        id={`C${+fields.length + 1}`}
        placeholder="Escribe aquí..."
        variant="outlined"
      />);
      setFields([...currentFields])
      if(currentFields.length == props.max) {
        setIcon(<RemoveCircleOutlineRoundedIcon />);
      }

    } else {
      //SUB
      setFields(fields.slice(0, -1));
      setIcon(<AddCircleOutlineRoundedIcon />);
    }
  };

  return (
    <FormControl sx={{ width: "40ch" }} className="text-group">
      <FormLabel component="legend">{props.label}</FormLabel>
      {fields}
      <IconButton color="primary" onClick={onChange}>
        {icon}
      </IconButton>
    </FormControl>
  );
}
