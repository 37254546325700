import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { FormLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function Dropdown(props) {
  const [selection, setSelection] = React.useState(null);
  const options = [];
  
  props.options.forEach((op, index) => {

    if (props.op?.category_dependency_input !== null) {
      if (props.currentAnswers[props.op?.category_dependency_input]?.choice_label !== props.op?.category_dependency_answer) {
        return;
      }
    }

    options.push(
      <MenuItem value={op.choice_label}>{op.choice_label}</MenuItem>
    );
  });

  const handleChange = (event) => {
    setSelection(event.target.value);
    props.selectCallback({
      block_id: props.userData.block,
      user_id: props.userData.id,
      answer: event.target.value,
      question_type: "select",
      id_question: props.id,
      question: props.label,
    });
  };
  return (
    <FormControl>
      <FormLabel component="legend">{props.label}</FormLabel>
      {props.answer && (
        <Select id={props.label} defaultValue={props.answer} onChange={handleChange}>
          {options}
        </Select>
      )}
      {!props.answer && (
        <Select id={props.label} value={selection || "none"} onChange={handleChange}>
          <MenuItem value="none" disabled>
            Selecciona una opción
          </MenuItem>
          {options}
        </Select>
      )}
    </FormControl>
  );
}
