import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:3501";

let instance = axios.create({
    baseURL: baseUrl + "/purchases"
});

export const createPurchase = async (purchase) => {
    try {
        const response = await instance.post("/create-purchase", purchase);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getPurchases = async () => {
    try {
        const response = await instance.get("/get-purchases");

        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getPurchase = async (id) => {
    try {
        const response = await instance.get(`/get-purchase/${id}`);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getPurchaseByUser = async (id) => {
    try {
        const response = await instance.get(`/get-purchase-by-user/${id}`);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const updatePurchase = async (id, purchase) => {
    try {
        const response = await instance.put(`/update-purchase/${id}`, purchase);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const deletePurchase = async (id) => {
    try {
        const response = await instance.delete(`/delete-purchase/${id}`);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const updatePurchaseUser = async (id, purchase) => {
    try {
        const response = await instance.put(`/update-purchase-user/${id}`, purchase);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}
