import axios from "axios";

const baseUrl = process.env.REACT_APP_ORDERS_API_URL || "http://localhost:3501";

let instance = axios.create({
    baseURL: baseUrl,
});

export const createOrder = async (order) => {
    try {
        const response = await instance.post("/orders", order);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getOrder = async (id) => {
    try {
        const response = await instance.get("/orders/id/" + id);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const encode = async (information) => {
    try {
        const response = await instance.post("/checkout/multiencode", information);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const config = async () => {
    try {
        const response = await instance.get("/config");

        return response.data;
    } catch (error) {
        console.error(error);
    }
}