import "./Breadcrumbs.css";

export default function Breadcrumbs(props) {

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    function formatCurrent(text) {
        const [number, ...titleParts] = text.split(". ");
        const title = titleParts.join(". ");
        return `${capitalizeFirstLetter(title)}`;
    }

    const breadcrumbs = props.mainTitle.map(breadcrumb => {
        return (
            <>
                <a className="breadcrumb" href={breadcrumb.link}>{breadcrumb.mainTitle}</a>
                <span>/</span>
            </>
        );
    });

    return (
        <div className="breadcrumbs">
            {breadcrumbs}
            <p className="breadcrumb-module">
                {formatCurrent(props.current) + " / "}
            </p>
            <p className="breadcrumb-current">
                {props.section && `${props.section.number}. ${props.section.title}`}
            </p>
        </div>
    );
}
