import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:3501";

let instance = axios.create({
    baseURL: baseUrl + "/modules",
});

export const getModulesProgress = async (id) => {
    try {
       // console.log("id", id);
        const response = await instance.get("/get-modules-progress/" + id);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}